.allProjectsContainer {
    height: 100%;
    margin-top: 2%;

    h2 {
        font-size: 2.5em;
        border-bottom: 1px solid white;
        width: 50%;
        margin-bottom: 2%;

        @media #{$mobile} {
            width: 100%; }

        @media #{$tablet} {
            width: 30%; } }

    .TLProjects {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        margin-left: 2%;

        @media #{$tablet} {
            flex-direction: column;
            align-content: center; } }

    .devProjects {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        margin-left: 2%;

        @media #{$tablet} {
            flex-direction: column;
            align-content: center; } } }
