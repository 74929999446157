// Dark Mode
$primary-color-dark: black;
$secondary-color-dark: white;
$third-color-dark: #F3D687;
$fourth-color-dark: #6FB7EB;
$fifth-color-dark: #FFFFFF;

//Light Mode
$primary-color-light: white;
$secondary-color-light: black;
$third-color-light: #F3D687;
$fourth-color-light: #6FB7EB;
$fifth-color-light: #FFFFFF;


body {
    background-color: $primary-color-dark;
    color: $secondary-color-dark; }

body.light-mode {
    background-color: $primary-color-light;
    color: $secondary-color-light; }

h2.light-mode {
    border-bottom: 1px solid black; }
