.project {
    width: 45%;
    padding: 2%;
    margin-bottom: 3%;
    border: 1px solid white;
    border-radius: 8px;

    @media #{$tablet} {
        width: 90%;
        margin-bottom: 7%; }

    .titleLinks {
        display: flex;
        justify-content: center;
        flex-direction: row;

        .noLink {
            color: green;
            font-size: 1.4em; }

        h5 {
            margin: 1% 5%;

            a {
                text-decoration: none;
                color: green;
                font-size: 1.4em; } } }

    img {
        margin: auto;
        width: 37vw;
        height: 45vh;
        max-width: 45vw;
        max-height: 50vh;
        opacity: 1;
        filter: grayscale(0%);
        transition: .5s ease;
        backface-visibility: hidden;
        @media (min-width: 1250px) {
            &:hover {
                opacity: 0.1;
                filter: grayscale(90%); } }

        @media #{$tablet} {
            width: 500px;
            height: 300px; }

        @media #{$mobile} {
            width: 300px;
            height: 200px;
            flex-direction: column;
            justify-content: center;
            align-items: center; } }


    .projectContent {
        position: absolute;
        margin-left: 2%;
        margin-top: 1%;
        z-index: -1;
        transform: translate(3%, 15%);
        -ms-transform: translate(3%, 15%);

        @media (min-width: 1250px) {
            &:hover {
                z-index: 2; } }

        @media #{$tablet} {
            position: relative;
            transform: translate(0,0);
            -ms-transform: translate(0,0); }


        .role {
            font-size: 1.2em;
            margin-top: 2%; }

        .projectInfo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 80%;


            ul {
                display: flex;
                justify-content: center;
                width: 90%;
                list-style: circle;

                li {
                    line-height: 1.5;
                    font-size: .9rem;
                    margin: 1% 0; } } } } }
