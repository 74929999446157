.landingMain {
    margin: 2%;

    .stats {
        margin-top: 4%;

        h2 {
            font-size: 2em; }

        .statsDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: space-between;
            height: 100%;
            margin-bottom: 2%;
            margin-top: 1%;

            @media #{$mobile} {
                flex-direction: column;
                align-content: center; }

            @media #{$tablet} {} }


        .frontEnd {
            margin: 10px;
            width: 25%;

            @media #{$mobile} {
                width: 90%; }

            @media #{$tablet} {
                width: 45%; }

            li {
                margin: 1% 2%; }

            h3 {
                font-size: 1.6em;
                margin-bottom: 3%; } }

        .backEnd {
            margin: 10px;
            width: 25%;

            @media #{$mobile} {
                width: 90%; }

            @media #{$tablet} {
                width: 30%; }

            li {
                margin: 1% 2%; }

            h3 {
                font-size: 1.6em;
                margin-bottom: 3%; } }

        .other {
            margin: 10px;
            width: 25%;

            @media #{$mobile} {
                width: 90%; }

            @media #{$tablet} {
                width: 45%;
                margin-top: 5%; }

            li {
                margin: 1% 2%; }

            h3 {
                font-size: 1.6em;
                margin-bottom: 3%; } }

        .unique {
            margin: 10px;
            width: 25%;

            @media #{$mobile} {
                width: 90%; }

            @media #{$tablet} {
                width: 45%; }

            li {
                margin: 1% 2%; }

            h3 {
                font-size: 1.6em;
                margin-bottom: 3%; } } } }
