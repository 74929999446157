// Dark Mode
$primary-color-dark: black;
$secondary-color-dark: white;
$third-color-dark: #F3D687;
$fourth-color-dark: #6FB7EB;
$fifth-color-dark: #FFFFFF;

//Light Mode
$primary-color-light: white;
$secondary-color-light: black;
$third-color-light: #F3D687;
$fourth-color-light: #6FB7EB;
$fifth-color-light: #FFFFFF;


$primary-font: Proza Libre, sans-serif;
$secondary-font: Proza Libre, serif;
$header-font: Rubik, sans-serif;

$mobile: '(max-width : 500px)';
$tablet: '(max-width : 1250px)';
