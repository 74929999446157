.nav {
    height: 3%;
    display: flex;
    justify-content: center;
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2%;

        li {
            .projectLink {
                &:hover {
                    color: purple;
                    cursor: pointer; } }

            .light-bulb {
                &:hover {
                    cursor: pointer; } } } }


    a {
        text-decoration: none;
        color: grey; }

    @media #{$mobile} {
        width: 90vw;
        height: 4%; } }




